/* remove box border for registraion course buttons */
.ui.button.registration-button {
  margin: 10px !important;
  border: none !important;
  box-shadow: none !important;
  line-height: 1.4285em;
}

.ui.inverted.button.active,
.ui.inverted.button:focus {
  box-shadow: 0 0 0 4px var(--brown) inset !important;
}

/* checkbox toggle colors */
.ui.toggle.checkbox input:focus:checked:hover ~ .box:before,
.ui.toggle.checkbox input:focus:checked:hover ~ label:before {
  background-color: var(--primary) !important;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: rgba(128, 128, 128, 0.6);
}
